import React, { Component } from 'react';
import moment from 'moment';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import TextField from '@mui/material/TextField';
import DateAdapter from '@mui/lab/AdapterMoment';

import AppointmentSlotPicker from './AppointmentSlotPicker'

import { withTrans } from "../../i18n/withTrans"

class AppointmentDatePicker extends Component {

  constructor(props) {
    super(props);

    const today = moment()
    var selectedDate = today.startOf('day').toDate()

    var selectedSlot = null
    var selectedSlotId = null

    if (props.slot) {
      selectedSlot = props.slot
      selectedSlotId = props.slot.id
      selectedDate = props.slot.start.toDate()
    }

    this.state = {
      selectedDate: selectedDate,
      selectedSlot: selectedSlot,
      selectedSlotId: selectedSlotId,
      startTime: "",
      endTime: "",
      location: ""
    };
  }

  componentDidUpdate(prevProps, prevState) {
  }

  handleDateChange = date => {

    this.setState({ selectedDate: date.toDate() })
  }

  handleInputChange = event => {

    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleSlotPickerChange = (slotId, slot) => {

    this.setState({
      selectedSlot: slot,
      selectedSlotId: slotId
    })
  }

  nextDisabled = () => {

    if (this.state.selectedDate && this.state.selectedSlot) {
      return false
    }

    return true
  }

  handleNext = event => {

    if (this.state.selectedSlot && this.state.selectedSlotId) {
      this.props.onSelectedSlot(this.state.selectedSlotId, this.state.selectedSlot)
    }
  }

  render() {
    const { t } = this.props
    return (
      <LocalizationProvider dateAdapter={DateAdapter}>
        <React.Fragment>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Typography variant="body1" gutterBottom>
                {t('appointment.slot.message')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="h2" gutterBottom>
                {t('appointment.slot.day')}
              </Typography>
              <MobileDatePicker
                inputVariant="outlined"
                label={t('appointment.slot.dayLabel')}
                id="selectedDate"
                name="selectedDate"
                disablePast
                fullWidth
                value={this.state.selectedDate}
                onChange={this.handleDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
              {/* <DatePicker
                inputVariant="outlined"
                label={t('appointment.slot.dayLabel')}
                id="selectedDate"
                name="selectedDate"
                disablePast
                fullWidth
                value={this.state.selectedDate}
                onChange={this.handleDateChange}
              /> */}
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="h2" gutterBottom>
                {t('appointment.slot.time')}
              </Typography>
              <AppointmentSlotPicker date={this.state.selectedDate} onChange={this.handleSlotPickerChange} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                variant="contained"
                color="primary"
                disabled={this.nextDisabled()}
                onClick={this.handleNext}
                className=""
              >
                {t('appointment.slot.button')}
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      </LocalizationProvider>

    )
  }
}

export default withTrans(AppointmentDatePicker);