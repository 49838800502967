import React, { Component } from 'react'

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';

import moment from 'moment';
import ZurichTime from '../../helpers/ZurichTimeFormat';

import { withTrans } from "../../i18n/withTrans"

//Actions
import {
    readAppointmentSlotsBetweenDates
} from "../../helpers/Database";

import AppointmentSlotViewModel from '../../helpers/AppointmentSlotViewModel'

class AppointmentSlotPicker extends Component {

    constructor() {
        super();

        this.state = {
            availableSlotOptions: [],
            selectedSlotOption: null
        };
    }

    componentDidMount() {
        this.updateSlots(this.props.date)
    }

    updateSlots = (date) => {
        const startDate = date
        const endDate = ZurichTime.momentZh(date).add(moment.duration(1, 'days')).toDate()

        // Read available appointment slots
        readAppointmentSlotsBetweenDates(startDate, endDate, true).then(querySnapshot => {
            var slotOptions = []
            querySnapshot.forEach(doc => {
                slotOptions.push(new AppointmentSlotViewModel(doc.id, doc.data()))
            })
            this.setState({ availableSlotOptions: slotOptions, selectedSlotOption: null })
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.date !== prevProps.date) {
            this.updateSlots(this.props.date)
        }
    }

    handlePickSlotOption = (event, slotOption) => {
        this.setState({ selectedSlotOption: slotOption })
        if (slotOption) {
            this.props.onChange(slotOption.slotId, slotOption.slot)
        } else {
            this.props.onChange(null, null)
        }
    }

    render() {
      const { t } = this.props
        return (
            <Autocomplete
                variant="outlined"
                id="combo-box-demo"
                options={this.state.availableSlotOptions}
                value={this.state.selectedSlotOption}   
                onChange={this.handlePickSlotOption}
                getOptionLabel={(slotOption) => (slotOption.appointmentTime)}
                renderInput={(params) => <TextField {...params}
                    label={t('appointment.slot.timeLabel')}
                    variant="outlined" />}
            />
        );
    }
}

export default withTrans(AppointmentSlotPicker);